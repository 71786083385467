import http from 'http.js';
import ko from 'knockout';
import * as Sentry from '@sentry/browser';

import 'common/ko/validation.js';
import {localStorageGetItem, localStorageSetItem} from 'core/utils';

export class User {
    constructor() {
        this.id = ko.observable();
        this.authToken = ko.observable();
        this.profileId = ko.observable();
        this.userType = ko.observable();
        this.username = ko.observable();
        this.firstName = ko.observable();
        this.lastName = ko.observable();
        this.name = ko.observable();
        this.mobile = ko.observable();
        this.avatar = ko.observable();
        this.isStaff = ko.observable();
        this.links = ko.observableArray();
        this.loaded = false;
        this.showDropdown = ko.observable(false);
        // Fields for logging in, keep separate from fields displayed in nav
        this.loginUsername = ko.observable().extend({required: true});
        this.loginPassword = ko.observable().extend({required: true});
        // Fields for registering, kept separate from nav / login fields
        this.registrationPassword = ko.observable().extend({
            required: true,
            maxLength: 255,
        });
        this.registrationPasswordConfirm = ko.observable().extend({
            required: true,
            maxLength: 255,
            equal: this.registrationPassword,
        });
        this.registrationEmail = ko.observable().extend({
            email: true,
            checkEmail: true,
            required: true,
        });
        this.registrationUsername = ko.observable().extend({
            checkUsername: true,
            required: true,
        });
        this.registrationFirstName = ko.observable().extend({
            required: true,
            maxLength: 100,
        });
        this.registrationLastName = ko.observable().extend({
            required: true,
            maxLength: 100,
        });
        this.loginErrors = ko.validation.group([
            this.loginUsername,
            this.loginPassword,
        ]);
        this.registrationErrors = ko.validation.group([
            this.registrationPassword,
            this.registrationPasswordConfirm,
            this.registrationEmail,
            this.registrationFirstName,
            this.registrationLastName,
            this.registrationUsername,
        ]);

        this.fullName = ko.computed(() => {
            return `${this.firstName()} ${this.lastName()}`;
        });
        this.fullName.subscribe((value) => {
            let details = localStorageGetItem('intake-practice-details');
            details = {
                ...JSON.parse(details),
                practitionerName: value,
                timestamp: new Date().getTime(),
            };
            localStorageSetItem(
                'intake-practice-details',
                JSON.stringify(details),
            );
        });
    }

    toggleDropdown(_, elem) {
        this.showDropdown(!this.showDropdown());
        if (elem && this.showDropdown()) {
            elem.target.focus();
        }
    }

    // Try to login, and if successful reload data
    async login() {
        if (this.loginErrors().length) {
            this.loginErrors.showAllMessages();
            throw new Error('Login field errors');
        }
        const data = {
            username: this.loginUsername(),
            password: this.loginPassword(),
        };
        await http.post({url: '/api/base/v1/login/', data});
        this.loaded = false;
        await this.load();
    }

    logout() {
        Sentry.configureScope((scope) => scope.setUser(null));
        return http.post({url: '/api/base/v1/logout/'});
    }

    async register(source) {
        if (this.registrationErrors().length) {
            this.registrationErrors.showAllMessages();
            throw new Error('Registration field errors');
        }
        const data = {
            password: this.registrationPassword(),
            email: this.registrationEmail(),
            first_name: this.registrationFirstName(),
            last_name: this.registrationLastName(),
        };
        if (source) {
            data.source = source;
        }
        if (this.registrationUsername()) {
            data.username = this.registrationUsername();
        }

        const respData = await http.post({
            url: '/api/base/v1/register-member/',
            data,
        });
        // We can't use email to auth until user has confirmed it, so for
        // now we take whatever username was generated and use for login
        if (!this.registrationUsername()) {
            this.registrationUsername(respData.username);
        }
    }

    async registerAndLogin(source) {
        await this.register(source);
        this.loginUsername(this.registrationUsername());
        this.loginPassword(this.registrationPassword());
        await this.login();
    }

    async load() {
        if (this.loaded) {
            return;
        }
        let respData;
        try {
            respData = await http.get({
                url: '/api/base/v1/user-info/',
            });
        } catch (ex) {
            if (!ex.message) {
                return;
            }
            const status = JSON.parse(ex.message).status;
            if (status !== 401) {
                throw new Error(ex.message);
            }
            return;
        } finally {
            this.loaded = true;
        }
        if (respData) {
            this.id(respData.id);
            this.authToken(respData.auth_token);
            this.profileId(respData.profile_id);
            this.userType(respData.user_type);
            this.username(respData.username);
            this.firstName(respData.first_name);
            this.lastName(respData.last_name);
            this.name(respData.display_name);
            this.mobile(respData.mobile);
            this.avatar(respData.avatar);
            this.isStaff(respData.is_staff);
            this.links(respData.links);
            Sentry.configureScope((scope) => {
                scope.setUser({
                    username: this.username(),
                    id: this.id(),
                });
            });
        }
    }

    async loadAvatar() {
        const data = await http.get({url: '/api/base/v1/user-info/'});
        this.avatar(data.avatar);
    }

    toString() {
        if (this.username()) {
            return `User ${this.username()}`;
        } else {
            return 'Anonymous User';
        }
    }
}

export default new User();
