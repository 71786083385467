import ko from 'knockout';

// Stop bindings applying below this element. Used to allow one model to
// bind to the whole page, and others to still control sub-elements.
ko.bindingHandlers.stopBinding = {
    init() {
        return {controlsDescendantBindings: true};
    },
};
ko.virtualElements.allowedBindings.stopBinding = true;
