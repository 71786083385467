import ko from 'knockout';
import 'knockout.validation';

const origApplyBindings = ko.applyBindings;
ko.applyBindings = function(viewModel, rootNode = document.body) {
    // Override Knockout Validation's override of ko.applyBindings
    // that doesn't set default 2nd parameter value
    // eslint-disable-next-line max-len
    // https://github.com/Knockout-Contrib/Knockout-Validation/blob/2.0.3/src/ko.extensions.js#L36

    // reference:
    // https://github.com/knockout/knockout/issues/2420#issuecomment-430035260
    ko.validation.init();
    origApplyBindings(viewModel, rootNode);
};
